import React,{useEffect, useState} from 'react'
import { FilePond, registerPlugin } from 'react-filepond';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { useGetUserDetailMutation,useGetUserBookingMutation } from '../../features/property/propertySlice';
import { useParams } from 'react-router-dom';
import NavbarAndSideBar from '../../components/basic/navbarAndSideBar'
import { Col,Row, Table } from 'reactstrap';
import TableTabComponent from '../../components/usersmanagement/tableTabComponent';

function UserDetailPage() {
    const [files, setFiles] = useState([]);
    const [getuserDetail, {isLoading }] = useGetUserDetailMutation()
    const [getUserBookings, {isLoading:isLoadingUserBooking}]=useGetUserBookingMutation()
    const [userDetail,setUserDetail] = useState()
    const [userBooking,setUserBooking] = useState()
   
    const {id}=useParams()


    const handleGetUserBookinng=async()=>{
      try{
        const params ={
          user_id:id,
        }
        const response = await getUserBookings(params).unwrap();
        console.log(response.results)
        setUserBooking(response.results)
       

      }
      catch(error){
          console.log(error)
      }
    }


    const handleGetUserDetail=async()=>{
        try{
            const response = await getuserDetail(id).unwrap();
            console.log(response)
            setUserDetail(response)
            if (response.profile){
                fetch(`https://dev-api.shortstaybuddy.com${response.profile}`
                )
                .then(res => res.blob())
                .then(blob => {
                  const file = new File([blob], 'first_image.jpg', { type: blob.type });
                  
                  setFiles([
                    {
                      source: file,
                      options: {
                        type: 'local'
                      }
                    }
                  ]);
                });
            }
        }catch(error){
            console.log(error)
        }
    }

    useEffect(()=>{
      handleGetUserDetail();
      handleGetUserBookinng();
    },[]);
  return (
    <>
            <NavbarAndSideBar />
            <div className="content-page" id="content-page">
                <div className="container-fluid">
                    
                    <Row style={{ marginBottom: "100px" }}>
                        <Col sm="12 " className='px-lg-5 px-md-3 px-sm-2'>
                            <div className='d-flex justify-content-between '>
                            <div className="">
                    <h3 className="card-title">{userDetail&&userDetail.first_name +" "+userDetail.last_name}</h3>
                  </div>
     
      
                    </div>
                        </Col>
                        <Col sm="12 mb-5" className='px-lg-5 px-md-3 px-sm-2'>
                        <div className='w-100 px-2'>
      <div className='w-100 '>
      {
        userDetail&&userDetail.role==="host"?
        <div className="row border p-2 rounded-sm"> 
<div className='col-12'>
<label>Current Balance<span className='text-danger'>*</span></label>
<input type="text" name='user' className="form-control" required placeholder="" value={userDetail&&userDetail.balance} disabled />
</div>
      </div>
        :""

      }
      <div className="row border p-2 rounded-sm mt-3"> 
      <div className="col-12">
              <label>Image <span className='text-danger'>*</span></label>
              
              <FilePond
                files={files}
                allowMultiple={false}
                disabled
                name="avatar_url"
                stylePanelLayout="compact"
                styleButtonRemoveItemPosition="left"
                styleButtonProcessItemPosition="right"
                styleLoadIndicatorPosition="right"
                styleProgressIndicatorPosition="right"
                styleButtonRemoveItemAlign={false}
                acceptedFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                onupdatefiles={setFiles}
              />
            </div>
           

      <div className='col-md-6'>
<label>Name<span className='text-danger'>*</span></label>
<input type="text" name='user' className="form-control" required placeholder="" value={userDetail&&userDetail.first_name +" "+ userDetail.last_name} disabled />
</div>
      <div className='col-md-6'>
<label>Email<span className='text-danger'>*</span></label>
<input type="text" name='user' className="form-control" required placeholder="" value={userDetail&&userDetail.email} disabled />
</div>
 </div>

<div className="row border p-2 rounded-sm mt-3"> 

<div className='col-md-6'>
<label>Phone<span className='text-danger'>*</span></label>
<input type="text" name='user' className="form-control" required placeholder="" value={userDetail&&userDetail.phone_number} disabled />
</div>
      <div className='col-md-6'>
<label>Address<span className='text-danger'>*</span></label>
<input type="text" name='user' className="form-control" required placeholder="" value={userDetail&&userDetail.address} disabled />
</div>

<div className='col-md-6'>
<label>Company Name<span className='text-danger'>*</span></label>
<input type="text" name='user' className="form-control" required placeholder="" value={userDetail&&userDetail.company_name} disabled />
</div>



</div>

<div className="row border p-2 rounded-sm mt-3"> 

<div className='col-md-6'>
<label>ID Type<span className='text-danger'>*</span></label>

<select name="national_id_type" class="form-control"  value={userDetail&&userDetail.national_id_type} disabled >
<option value="">Select ID Type</option>
<option value="national_id">National ID</option>
<option value="passport">Passport</option>
<option value="driving_license">Driving License</option>
<option value="student_id">Student ID</option>
</select>
</div>
      <div className='col-md-6'>
<label>ID Number<span className='text-danger'>*</span></label>
<input type="text" name='user' className="form-control" required placeholder="" value={userDetail&&userDetail.national_id_number
} disabled />
</div>

<div className='col-md-6'>
<label>Gender<span className='text-danger'>*</span></label>
<select name="national_id_type" class="form-control"  value={userDetail&&userDetail.gender} disabled >
<option value="">Select Gender</option>
<option value="male">Male</option>
<option value="female">Female</option>


</select>
</div>

<div className='col-md-6'>
<label>Date OF Birth<span className='text-danger'>*</span></label>
<input type="text" name='user' className="form-control" required placeholder="" value={userDetail&&userDetail.dob} disabled />
</div>

<div className='col-md-6'>
<label>Nationality<span className='text-danger'>*</span></label>
<input type="text" name='user' className="form-control" required placeholder="" value={userDetail&&userDetail.nationality} disabled />
</div>
<div className='col-md-6'>
<label>Role<span className='text-danger'>*</span></label>
<input type="text" name='user' className="form-control" required placeholder="" value={userDetail&&userDetail.role} disabled />
</div>


</div>

<div className="row border p-2 rounded-sm mt-3"> 
<label>Bio<span className='text-danger'>*</span></label>

<textarea className="form-control" disabled name="description" rows="3" value={userDetail && userDetail.description}></textarea>
</div>


<div className="row border p-2 rounded-sm mt-3"> 
<div className='col-md-6'>
<label>Facebook Link<span className='text-danger'>*</span></label>
<input type="text" name='user' className="form-control" required placeholder="" value={userDetail&&userDetail.facebook_link} disabled />
</div>

<div className='col-md-6'>
<label>Twitter Link<span className='text-danger'>*</span></label>
<input type="text" name='user' className="form-control" required placeholder="" value={userDetail&&userDetail.twitter_link
} disabled />
</div>

<div className='col-md-6'>
<label>Instagram Link<span className='text-danger'>*</span></label>
<input type="text" name='user' className="form-control" required placeholder="" value={userDetail&&userDetail.instagram_link
} disabled />
</div>

<div className='col-md-6'>
<label>Tiktok Link<span className='text-danger'>*</span></label>
<input type="text" name='user' className="form-control" required placeholder="" value={userDetail&&userDetail.tiktok_link
} disabled />
</div>

<div className='col-md-6'>
<label>LinkedIn Link<span className='text-danger'>*</span></label>
<input type="text" name='user' className="form-control" required placeholder="" value={userDetail&&userDetail.linkedin_link}
 disabled />
</div>

<div className='col-md-6'>
<label>WhatsApp Number<span className='text-danger'>*</span></label>
<input type="text" name='user' className="form-control" required placeholder="" value={userDetail&&userDetail.whatsapp_number
} disabled />
</div>




</div>










    
      </div>
      
                    </div>
                        </Col>
                        <Col sm='12'>


                        {userBooking && (
                                <TableTabComponent userBooking={userBooking} isLoadingUserBooking={isLoadingUserBooking} />
                            )}                          
                        </Col>
                    </Row>
                    
                </div>
            </div>
        </>
  )
}

export default UserDetailPage