import React, { useEffect, useState } from 'react';
import NavbarAndSideBar from '../../components/basic/navbarAndSideBar';
import { Col, Row } from "reactstrap";
import DataTable from "react-data-table-component";
import { useGetUsersMutation } from '../../features/property/propertySlice';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { format, parseISO } from 'date-fns';
import { Link } from 'react-router-dom';
import DeleteUserModal from '../../components/usersmanagement/deleteUserModal';

function Users() {
    const [getusers] = useGetUsersMutation();
    const [users, setUsers] = useState([]);
    const [toggledClearRows, setToggleClearRows] = useState(false);
    const [filterText, setFilterText] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [userDetail, setUserDetail] = useState(null);
    const [pending, setPending] = useState(true);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);

    const handleGetUsers = async (page, perPage) => {
        setPending(true);
        try {
            const response = await getusers({ page, limit: perPage }).unwrap();
            console.log(response);
            setUsers(response.results);
            setTotalRows(response.count);
        } catch (error) {
            console.error(error);
        } finally {
            setPending(false);
        }
    };

    useEffect(() => {
        handleGetUsers(page, perPage);
    }, [page, perPage]);

    const handleDeleteUser = (row) => {
        setUserDetail(row);
        setDeleteUserModal(true);
    };

    const handleChange = ({ selectedRows }) => {
        setSelectedRows(selectedRows);
    };

    const handleClearRows = () => {
        setToggleClearRows(!toggledClearRows);
    };

    const formatDate = (dateString) => {
        const date = parseISO(dateString);
        return format(date, "MMM d, yyyy");
    };

    const filteredItems = users.filter(
        item => item.first_name && item.first_name.toLowerCase().includes(filterText.toLowerCase())
    );

    const [viewUserModal, setViewUserModal] = useState(false);
    const [deleteUserModal, setDeleteUserModal] = useState(false);

    const toggleDeleteModal = () => {
        setDeleteUserModal(!deleteUserModal);
        handleGetUsers(page, perPage);
    };

    const columns = [
        {
            id: 'id',
            name: 'Image 1',
            selector: row => (
                <div style={{ width: "45px", height: "45px" }} className='rounded-circle my-2'>
                    {row.profile ? (
                        <img src={row.profile} style={{ objectFit: "cover", height: "100%", width: "100%" }} alt="image" />
                    ) : ""}
                </div>
            ),
            width: "fit-content",
        },
        {
            name: 'Title',
            selector: row => (
                <div className='d-flex justify-content-between w-full'>
                    <div>
                        <h5 className='text-wrap'>{row.first_name + " " + row.last_name}</h5>
                        <p>{row.email}</p>
                    </div>
                    <div className='d-flex justify-content-center align-items-center mx-1'>
                        {row.is_number_verified ? <i className="ri-checkbox-circle-fill text-success"></i> : ""}
                    </div>
                </div>
            ),
            width: '30%',
        },
        {
            name: '',
            selector: row => (
                <div className=' py-1'>
                    <div>
                        <button type="button" className="btn mb-1 iq-bg-primary">
                            {row.is_staff ? "Admin" : row.role}
                        </button>
                        <p>{formatDate(row.date_joined)}</p>
                    </div>
                </div>
            ),
            width: '30%',
        },
        {
            name: 'Actions',
            cell: row => (
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <Link to={`/users/${row.id}`}
                        className='text-secondary action-link'
                        size="sm">
                        <i className="ri-eye-fill mr-1"></i>
                        View
                    </Link>
                    <Link to={`/users/edit/${row.id}`} className='text-primary action-link mx-3' size="sm">
                        <i className="ri-pencil-fill mr-1"></i>
                        Edit
                    </Link>
                    <div className='text-danger action-link' onClick={() => handleDeleteUser(row)}>
                        <i className="ri-delete-bin-fill mr-1"></i>Delete
                    </div>
                </div>
            ),
            width: "fit-content",
        },
    ];

    return (
        <>
            <NavbarAndSideBar />
            <div className="content-page" id="content-page">
                <div className="container-fluid">
                    <Row style={{ marginBottom: "100px" }}>
                        <Col sm="12" className='px-lg-5 px-md-3 px-sm-2'>
                            <div className='d-flex justify-content-between mb-4'>
                                <h4 className="card-title">Users</h4>
                                <Link to='/users/add' className="btn btn-primary btn-sm mr-2">New User</Link>
                                <DeleteUserModal deleteUserModal={deleteUserModal} toggleDeleteModal={toggleDeleteModal} userDetail={userDetail} />
                            </div>
                            <div className="iq-card iq-card-block iq-card-stretch iq-card-height overflow-hidden">
                                <div className="iq-card-header d-flex justify-content-between">
                                    <div className='text-primary d-flex align-content-center hover-icon'>
                                        <i className="ri-more-2-fill" style={{ fontSize: "25px", display: `${selectedRows.length > 0 ? '' : "none"}` }}></i>
                                    </div>
                                    <div className="search-box d-flex justify-content-end">
                                        <input type="text" className="text search-input" placeholder="Search By Name..."
                                            value={filterText}
                                            onChange={e => setFilterText(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div style={{ overflowX: 'auto' }}>
                                    <div style={{ minWidth: '1000px' }}>
                                        <DataTable
                                            columns={columns}
                                            data={filteredItems}
                                            pagination
                                            paginationServer
                                            paginationTotalRows={totalRows}
                                            onChangePage={setPage}               // Handle page change
                                            onChangeRowsPerPage={setPerPage}     // Handle rows per page change
                                            selectableRows
                                            onSelectedRowsChange={handleChange}
                                            clearSelectedRows={toggledClearRows}
                                            selectableRowsComponentProps={{
                                                type: 'checkbox',
                                            }}
                                            selectableRowsHighlight
                                            progressPending={pending}
                                            progressComponent={
                                                <div style={{ width: "100%" }}>
                                                    <Skeleton style={{ width: "100%", height: "30px" }} />
                                                    <Skeleton style={{ width: "100%", height: "30px" }} className='my-3' />
                                                    <Skeleton style={{ width: "100%", height: "30px" }} />
                                                    <Skeleton style={{ width: "100%", height: "30px" }} className='my-3' />
                                                </div>
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}

export default Users;
